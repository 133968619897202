import * as React from "react";
import { Component } from 'react';
import  { Container, Row, Col} from 'react-bootstrap';
import HTML5Video from '../global/video.js';
import CarmarionVideo from '../../video/vos-carmarion.mp4'
import DouglasVideo from '../../video/vos-douglas.mp4'
import EvanVideo from '../../video/vos-evan.mp4'
import JamellVideo from '../../video/vos-jamell.mp4';
import SadiyaVideo from '../../video/vos-sadiya.mp4'
import CleveVideo from '../../video/vos-cleve.mp4'

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined
    }
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;
    switch (video) {
      case "Carmarion":
        VideoThumb = '/carmarions-story-thumbnail.jpg'
        VideoSrc = CarmarionVideo
        VideoTrackLabel = "Carmarion's Story"
        break;
      case "Douglas":
        VideoThumb = '/dougs-story-thumbnail.jpg'
        VideoSrc = DouglasVideo
        VideoTrackLabel = "Douglas's Story"
        break;
      case "Evan":
      VideoThumb = '/evans-story-thumbnail.jpg'
      VideoSrc = EvanVideo
      VideoTrackLabel = "Evan's Story"
       break;
      case "Jamell":
      VideoThumb = '/jamells-story-thumbnail.jpg'
      VideoSrc = JamellVideo
      VideoTrackLabel = "Jamell's Story"
        break;
      case "Sadiya":
      VideoThumb = '/sadiyas-story-thumbnail.jpg'
      VideoSrc = SadiyaVideo
      VideoTrackLabel = "Sadiya's Story"
        break;
      case "Cleve":
      VideoThumb = '/cleves-story-thumbnail.jpg'
      VideoSrc = CleveVideo
      VideoTrackLabel = "Cleve's Story"
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel
    })
    //this.handleShow();
  }

  render() {
    return (
      <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
        <Container fluid className="hero voices-of-strength" />
        <Container className="voices-of-strength">
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                <h1 className="sub-title ">Voices of Strength</h1>
                <h1 className="title ">Coming together to silence stigma</h1>
              </div>
              <p>
              Stigma divides us. It isolates us and stands in the way of people making progress.
               “Voices of Strength” is a video series that celebrates personal stories of strength, perseverance, and triumph in the face of stigma.
                Together, our voices can silence the stigma surrounding HIV.
              </p>
              <Container>
                <Row className="videos">
                  <HTML5Video 
                    handleClose={this.handleClose} 
                    show={this.state.show} 
                    thumbnail={this.state.VideoThumb}
                    url={this.state.VideoSrc}
                    videoTrackLabel={this.state.VideoTrackLabel}
                  />
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img onClick={()=>this.handleClick("Carmarion")} src={'/carmarions-story-thumbnail.jpg'} />
                    <span className="title">
                      Carmarion's Story
                    </span>
                    <p className="description">
                      Carmarion’s story is one of resilience in the face of stigma—love triumphing over hate. 
                      See how she is working as a force of change in the community.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img onClick={()=>this.handleClick("Douglas")} src={'/dougs-story-thumbnail.jpg'} />
                    <span className="title">
                      Douglas’s Story
                    </span>
                    <p className="description">
                      Come along for Douglas’s journey with HIV: from his diagnosis in the early days of the epidemic to confronting stigma to finding acceptance, freedom, and happiness.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img onClick={()=>this.handleClick("Evan")} src={'/evans-story-thumbnail.jpg'} />
                    <span className="title">
                    Evan’s Story
                    </span>
                    <p className="description">
                      As a transgender man in the military, 
                      Evan faced bias and discrimination. He recounts his story about overcoming stigma and finding pride in being himself.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img onClick={()=>this.handleClick("Jamell")} src={'/jamells-story-thumbnail.jpg'} />
                    <span className="title">
                      Jamell's Story
                    </span>
                    <p className="description">
                      See how Jamell has moved past silence and stigma to find a sense of liberation in living his truth and sharing his story.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img onClick={()=>this.handleClick("Sadiya")} src={'/sadiyas-story-thumbnail.jpg'} />
                    <span className="title">
                      Sadiya's Story
                    </span>
                    <p className="description">
                      Sadiya's experience with overcoming stigma surrounding her identity has taken her from 
                      a place of fear and isolation to finding both a community and self-acceptance.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img onClick={()=>this.handleClick("Cleve")} src={'/cleves-story-thumbnail.jpg'} />
                    <span className="title">
                      Cleve's Story
                    </span>
                    <p className="description">
                    Learn the inspiration behind the AIDS Memorial Quilt, and how Cleve's advocacy has changed hearts and minds since the early days of the HIV epidemic.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Content;